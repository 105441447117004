import React, { Component } from "react";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import LoginWithData from "../Login/Login";
import Layout from "../Layout/Layout";
import "./App.scss";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route path="/login" component={LoginWithData} />
          <Route path="*" component={Layout} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
