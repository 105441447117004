import React from "react";
import gql from "graphql-tag";
import { graphql, withApollo } from "react-apollo";
import moment from "moment-timezone";
import MainHeader from "../../components/Main/MainHeader";
import MainContent from "../../components/Main/MainContent";
import { getLoggedInUser } from "../../utils/utils";
import android_icon from "../../assets/images/android.svg";
import ios_icon from "../../assets/images/ios.svg";
import Snackbar from "../../components/Snackbar/Snackbar";
import compose from "lodash.flowright";
import "./PushDetails.scss";

const pushDetailsQuery = gql`
  query($id: String!) {
    push(id: $id) {
      id
      pushtime
      query
      status
      payload
      numsent
      numfailed
      devicetypes
      dashboard_audience
    }
  }
`;

const removeScheduledPush = gql`
  mutation removeScheduledPush($push_id: String!) {
    removeScheduledPush(push_id: $push_id) {
      status
    }
  }
`;

const pushDetailsQueryOptions = {
  options: (ownProps) => {
    console.log("query params", ownProps.match.params);
    return {
      variables: { id: ownProps.match.params.id },
      fetchPolicy: "cache-and-network",
    };
  },
};

class PushDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      snackbarMessage: "",
    };
  }
  successRate = (numsent, numfailed) => {
    const numTotal = numsent + numfailed;
    if (numTotal === 0) return "-";
    return `${((numsent / numTotal) * 100).toFixed(1)}%`;
  };

  handleClickDeleteMessage = () => {
    this.setState({ snackbarMessage: "Deleting..." });
    console.log("deleting push with id: ", this.props.data.push.id);
    this.props
      .removeScheduledPush({
        variables: {
          push_id: this.props.data.push.id,
        },
      })
      .then((data) => {
        this.setState({ snackbarMessage: "" });
        this.props.history.push("/push/activity");
      })
      .catch((res) => {
        console.log(res);
        this.setState({ snackbarMessage: res.message });
      });
  };

  handleSnackbarCloseRequest = () => {
    this.setState({ snackbarMessage: "" });
  };

  render() {
    if (this.props.data.loading) return null;
    if (!this.props.data.push) return <div>No Status avail</div>;

    const pushDetails = { ...this.props.data.push };
    const payload = JSON.parse(pushDetails.payload);
    const payloadData =
      payload && payload.data && payload.data.data
        ? payload.data.data
        : payload;
    const query = JSON.parse(pushDetails.query);
    delete pushDetails.payload;
    delete pushDetails.query;
    const deviceType = pushDetails.devicetypes.includes("android")
      ? "android"
      : "ios";

    let messageSentHeading = "Message sent"; // status === 'succeeded'.
    let messageSentText = "Sent at "; // status === 'succeeded'.
    if (pushDetails.status.toLowerCase() === "pending") {
      messageSentHeading = "Scheduled message";
      messageSentText = "Scheduled to send at ";
    } else if (pushDetails.status.toLowerCase() === "running") {
      messageSentHeading = "Sending message";
      messageSentText = "Started sending at ";
    }

    return (
      <div className="PushDetails">
        <MainHeader title="Details">
          <a className="button" href={null} onClick={this.props.history.goBack}>
            Go back
          </a>
        </MainHeader>
        <MainContent>
          {/* Success rate */}
          <div className="success-rate section">
            <div className="delivered">
              <div className="number">{pushDetails.numsent}</div>
              <div className="text">Delivered</div>
            </div>
            <div className="meter">
              <div className="blingring">
                <div className="number">
                  {this.successRate(pushDetails.numsent, pushDetails.numfailed)}
                </div>
                <div className="text">Success rate</div>
              </div>
            </div>
            <div className="failed">
              <div className="number">{pushDetails.numfailed}</div>
              <div className="text">Failed</div>
            </div>
          </div>

          {/* Message sent */}
          <div className="message-sent section">
            <div className="section-heading">{messageSentHeading}</div>
            <div className="section-subheading">
              {messageSentText}
              {moment(pushDetails.pushtime, "x").format("YYYY-MM-DD HH:mm:ss")}
            </div>
            <div className="section-content">
              <ul className="message-sent-content infobox">
                {deviceType === "android" ? (
                  <React.Fragment>
                    <li className="col">Title</li>
                    <li className="col2">{payload.title}</li>

                    <li className="col">Description</li>
                    <li className="col2">{payload.message}</li>

                    <li className="col">Device</li>
                    <li className="col2">
                      <img
                        className="device-icon"
                        src={android_icon}
                        alt="device-icon"
                      />
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <li className="col">Title</li>
                    <li className="col2">
                      {payload.alert && typeof payload.alert === "object"
                        ? payload.alert.title
                        : payload.alert}
                    </li>
                    <li className="col">Description</li>
                    <li className="col2">
                      {payload.alert && typeof payload.alert === "object"
                        ? payload.alert.body
                        : ""}
                    </li>
                    <li className="col">Device</li>
                    <li className="col2">
                      <img
                        className="device-icon"
                        src={ios_icon}
                        alt="device-icon"
                      />
                    </li>
                  </React.Fragment>
                )}
                {!pushDetails.dashboard_audience ? null : (
                  <React.Fragment>
                    <li className="col">Audience</li>
                    <li className="col2">{pushDetails.dashboard_audience}</li>
                  </React.Fragment>
                )}
                {!payloadData.articleUrl ? null : (
                  <React.Fragment>
                    <li className="col">Article URL</li>
                    <li className="col2">
                      <a href={payloadData.articleUrl} target="_blank">
                        {payloadData.articleUrl}
                      </a>
                    </li>
                  </React.Fragment>
                )}
                {/* <li className="col">Icon</li>
                <li className="col2">
                  {payloadData.iconUrl ? (
                    <a href={payloadData.iconUrl} target="_blank">
                      <img
                        className="message-icon"
                        src={payloadData.iconUrl}
                        alt="message icon"
                      />
                    </a>
                  ) : null}
                </li> */}
              </ul>
            </div>
            <input
              type="submit"
              className={`delete-message-button ${
                pushDetails.status.toLowerCase() === "pending" ? "" : "hidden"
              }`}
              value="Delete Message"
              onClick={this.handleClickDeleteMessage}
            />
          </div>

          {/* Admin content */}
          <div
            className={`admin-content section ${
              getLoggedInUser().role === "admin" ? "" : "hidden"
            }`}
          >
            <div className="section-heading">Message payload</div>
            <div className="section-content">
              <div className="infobox">
                <pre>{JSON.stringify(payload, null, "    ")}</pre>
              </div>
            </div>

            <div className="section-heading">Message query</div>
            <div className="section-content">
              <div className="infobox">
                <pre>{JSON.stringify(query, null, "    ")}</pre>
              </div>
            </div>

            <div className="section-heading">Message details</div>
            <div className="section-content">
              <div className="infobox">
                <pre>{JSON.stringify(pushDetails, null, "    ")}</pre>
              </div>
            </div>
          </div>
        </MainContent>
        <Snackbar
          message={this.state.snackbarMessage}
          onRequestClose={this.handleSnackbarCloseRequest}
        />
      </div>
    );
  }
}

const PushDetailsWithData = compose(
  graphql(removeScheduledPush, { name: "removeScheduledPush" }),
  graphql(pushDetailsQuery, pushDetailsQueryOptions),
  withApollo
)(PushDetails);

export default PushDetailsWithData;
