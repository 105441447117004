import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import MainHeader from "../../components/Main/MainHeader";
import MainContent from "../../components/Main/MainContent";
import Input from "../../components/Input/Input";
import Scheduler from "../../components/Push/PushScheduler";
import Clock from "../../components/Clock/Clock";
import Snackbar from "../../components/Snackbar/Snackbar";
import { getLoggedInUser } from "../../utils/utils";
import phoneBackground from "../../assets/images/phone_background.png";
import allsvenskan_icon from "../../assets/images/allsvenskan.png";
import "./PushSend.scss";

const PushSendMutation = gql`
  mutation sendPush($input: SendPushInput!) {
    sendPush(input: $input) {
      status
    }
  }
`;

class PushSend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageTitle: "",
      messageBody: "",
      messageLink: "",
      recipients: "",
      sendPushStatus: "",
      schedulerSendNow: true,
      schedulerDate: "",
      schedulerTime: "",
      loggedInUser: getLoggedInUser()
    };
  }

  handleOnChange = e => {
    if (!e.target) {
      console.log('"e.target" is undefined');
      return;
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ sendPushStatus: "Sending message..." });

    const {
      messageTitle: title,
      recipients: audience,
      messageBody: message,
      messageLink: article_url,
      schedulerSendNow: send_now,
      schedulerDate: send_date,
      schedulerTime: send_time
    } = this.state;

    // Guard from sending faulty message.
    if (!title || title === "" || !audience || audience === "") {
      this.setState({
        sendPushStatus: "Failed sending - Title and Recipient must be set."
      });
      return;
    }

    // Send push message
    this.props
      .mutate({
        variables: {
          input: {
            title,
            audience,
            message,
            article_url,
            send_now,
            send_date,
            send_time
          }
        }
      })
      .then(res => {
        this.setState({ sendPushStatus: "Message sent" });
        this.setState({ messageTitle: "", messageBody: "", messageLink: "" });
      })
      .catch(res => {
        this.setState({ sendPushStatus: res.message });
      });
  };

  handleSendNowChange = sendNow => {
    this.setState({ schedulerSendNow: sendNow });
  };

  handleDateChange = momentObject => {
    // momentObject will be an object if it's a valid date, otherwise string.
    const date =
      momentObject && momentObject._isAMomentObject
        ? momentObject.format("YYYY-MM-DD")
        : momentObject;
    this.setState({ schedulerDate: date });
  };

  handleTimeChange = momentObject => {
    // momentObject will be an object if it's a valid time, otherwise string.
    const time =
      momentObject && momentObject._isAMomentObject
        ? momentObject.format("HH:mm")
        : momentObject;
    this.setState({ schedulerTime: time });
  };

  handleSnackbarCloseRequest = () => {
    this.setState({ sendPushStatus: "" });
  };

  render() {
    return (
      <div className="PushSend">
        <MainHeader title="Send" />
        <MainContent>
          <div className="content">
            {/* Preview */}
            <div className="section preview">
              <div className="section-heading">Preview</div>
              <div className="section-content">
                <div className="phone">
                  <div className="phone-speaker">
                    <div className="phone-speaker-hole" />
                  </div>
                  <div className="phone-display">
                    <img className="phone-display-background" src={phoneBackground} alt="phone-background" />
                    <Clock />
                    <div className="phone-message">
                      <div className="phone-message-header">
                        <img className="phone-message-header-icon" src={this.state.loggedInUser.team_icon || allsvenskan_icon} alt="App icon" />
                        <div className="phone-message-header-appname">{this.state.loggedInUser.team}</div>
                        <div className="phone-message-header-time">Now</div>
                      </div>                       
                      <div className="phone-message-title">
                        {this.state.messageTitle}
                      </div>
                      <div className="phone-message-body">
                        {this.state.messageBody}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Message */}
            <div className="section message">
              <form className="message-form" onSubmit={this.onSubmit}>
                <div className="section-heading">Schedule</div>
                <div className="section-subheading">
                  We can send the message immediately, or any time in the next 2
                  weeks.
                </div>
                <div className="section-content">
                  <Scheduler
                    onSendNowChange={this.handleSendNowChange}
                    onDateChange={this.handleDateChange}
                    onTimeChange={this.handleTimeChange}
                  />
                </div>

                <div className="section-heading">Message</div>
                <div className="section-subheading">
                  Type title, message and link (URL) to send.
                </div>
                <div className="section-content">
                  <div className="write-message">
                    <div className="input-box">
                      <Input
                        type="text"
                        name="messageTitle"
                        className="title"
                        placeholder="Title"
                        onChange={this.handleOnChange}
                        value={this.state.messageTitle}
                        required
                      />
                      <textarea
                        name="messageBody"
                        placeholder="Message"
                        rows="1"
                        onChange={this.handleOnChange}
                        value={this.state.messageBody}
                      />
                      <Input
                        type="url"
                        name="messageLink"
                        className="link"
                        placeholder="Link (http://www.example.com)"
                        onChange={this.handleOnChange}
                        value={this.state.messageLink}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="section-heading">Recipients</div>
                <div className="section-subheading">
                  Select which recipients to send to.
                </div>
                <div className="section-content">
                  <div className="recipients">
                    <div className="input-box">
                      <Input
                        type="radio"
                        id="all-my-users"
                        name="recipients"
                        className="all-my-users"
                        value="all-my-users"
                        label="All my users"
                        onChange={this.handleOnChange}
                        required
                      />
                      <Input
                        type="radio"
                        id="all-users-on-wifi"
                        name="recipients"
                        className="all-users-on-wifi"
                        value="all-users-on-wifi"
                        label="All users on wifi today"
                        onChange={this.handleOnChange}
                        disabled={!this.state.loggedInUser.team_key}
                        infoText="All users that have been using the app at least once today while connected to @fotboll wifi."
                        required
                      />
                      <Input
                        type="radio"
                        id="all-users-not-on-wifi"
                        name="recipients"
                        className="all-users-not-on-wifi"
                        value="all-users-not-on-wifi"
                        label="All users not on wifi today"
                        onChange={this.handleOnChange}
                        disabled={!this.state.loggedInUser.team_key}
                        infoText="All users that have not been using the app at least once today while connected to @fotboll wifi."
                        required
                      />
                      <Input
                        type="radio"
                        id="my-test-users"
                        name="recipients"
                        className="my-test-users"
                        value="my-test-users"
                        label="My test users"
                        onChange={this.handleOnChange}
                        disabled={
                          !this.state.loggedInUser.test_devices ||
                          this.state.loggedInUser.test_devices.length === 0
                        }
                        infoText={`Number of devices: ${
                          this.state.loggedInUser.test_devices.length
                        }`}
                        required
                      />
                    </div>
                  </div>
                </div>
                <Input
                  type="submit"
                  className="button"
                  value="Send"
                  disabled={this.state.sendPushStatus}
                />
              </form>
            </div>
          </div>
        </MainContent>
        <Snackbar
          message={this.state.sendPushStatus}
          onRequestClose={this.handleSnackbarCloseRequest}
        />
      </div>
    );
  }
}

export default graphql(PushSendMutation)(PushSend);
