import React from "react";
import "./MainContent.scss";

class MainContent extends React.Component {
  render() {
    console.log(this.props.children);
    return <div className="MainContent">{this.props.children}</div>;
  }
}

export default MainContent;
