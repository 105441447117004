import React from "react";
import { unique_array } from "../../utils/utils";
import "./Chips.scss";

class Chips extends React.Component {
  constructor() {
    super();
    this.state = {
      input: ""
    };
  }

  handleOnChange = e => {
    this.setState({ input: e.target.value });
  };

  handleOnKeyPress = e => {
    const key = e.keyCode || e.charCode || e.which;

    if (key === 13) {
      // 13 === 'enter' key.
      e.preventDefault();
      // If input field is empty, just ignore and bail.
      if (!this.state.input.trim()) return;

      // If pattern is not valid, flash error message and bail.
      const isValid = document.querySelector(".chip-input").reportValidity();
      if (!isValid) return;

      // If chip already exists, just ignore and bail.
      const chips = this.props.chips || [];
      if (chips.includes(this.state.input)) return;

      // Add a new chip.
      if (this.props.onAddChip) {
        this.props.onAddChip(this.state.input);
      }

      this.setState({ input: "" });
    }

    // Prevent invalid chars,
    // const validCharsRegexp = /[a-zA-Z0-9._-]/;
    // if (!validCharsRegexp.test(String.fromCharCode(key))) {
    //   // Prevent onChange to fire, so "input" state does not update.
    //   e.preventDefault();
    // }
  };

  handleOnClickDeleteChip = e => {
    const chipText = e.target.parentNode.querySelector(".chip-text")
      .textContent;
    if (this.props.onDeleteChip) {
      this.props.onDeleteChip(chipText);
    }
  };

  render() {
    const { shouldRender = true } = this.props;

    if (!shouldRender) return null;

    return (
      <div className="Chips">
        <div className="heading">{this.props.heading}</div>
        <div className="chip-list">
          {unique_array(this.props.chips).map(chip => {
            return (
              <div className="chip" key={chip}>
                <div className="chip-text">{chip}</div>
                <a
                  className="chip-delete"
                  onClick={this.handleOnClickDeleteChip}
                >
                  &#x2715;
                </a>
              </div>
            );
          })}
        </div>
        <input
          type="text"
          className="chip-input"
          title={this.props.title}
          onKeyPress={this.handleOnKeyPress}
          onChange={this.handleOnChange}
          value={this.state.input}
          placeholder={this.props.placeholder}
          pattern={this.props.pattern}
        />
      </div>
    );
  }
}

export default Chips;
