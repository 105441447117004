import React from "react";
import ReactTable from "react-table";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";
import MainHeader from "../../components/Main/MainHeader";
import MainContent from "../../components/Main/MainContent";
import Loading from "../../components/Loading/Loading";
import Snackbar from "../../components/Snackbar/Snackbar";
import android_icon from "../../assets/images/android.svg";
import ios_icon from "../../assets/images/ios.svg";
import compose from "lodash.flowright";

import "./PushCampaigns.scss";
import PushCampaignMessages from "./PushCampaignMessages";

const eventInfoQuery = gql`
  query($id: Int!, $campaign_id: Int!) {
    # query($id: Int!) {
    eventInfo(id: $id) {
      id
      hometeamid
      awayteamid
      timestamp
      arena
      ticketurl
      audience
      away_team
      home_team
      day
      time
    }
    pushes(campaign_id: $campaign_id) {
      # pushes {
      id
      pushtime
      createdat
      campaign_id
      query
      payload
      status
      devicetypes
    }
  }
`;

const removeCampaign = gql`
  mutation removeCampaign($campaign_id: Int!) {
    removeCampaign(campaign_id: $campaign_id) {
      status
    }
  }
`;

const eventInfoQueryOptions = {
  options: (ownProps) => {
    // search = ?eventId=1234
    const eventId = ownProps.location.search.slice(1).split("=")[1] || null;
    return {
      variables: {
        id: parseInt(eventId, 10),
        campaign_id: parseInt(ownProps.match.params.id, 10),
      },
    };
  },
};

const createCampaignMutationOptions = {
  name: "loadCampaign",
  options: (ownProps) => {
    return {
      variables: { event_id: parseInt(ownProps.match.params.id, 10) },
    };
  },
};

const createCampaignMutation = gql`
  mutation loadCampaign(
    $event_id: Int!
    $default_campaign_id: Int
    $previous_campaign_id: Int
  ) {
    loadCampaign(
      event_id: $event_id
      default_campaign_id: $default_campaign_id
      previous_campaign_id: $previous_campaign_id
    ) {
      id
      messages {
        message
        title
        article_url
        audience
        send_now
        send_date
        send_time
        campaign_id
      }
      event_info {
        id
        hometeamid
        awayteamid
        home_team
        away_team
        day
        timestamp
        arena
        ticketurl
      }
      next_event_info {
        id
        hometeamid
        awayteamid
        home_team
        away_team
        day
        timestamp
        arena
        ticketurl
      }
    }
  }
`;

const removeScheduledPush = gql`
  mutation removeScheduledPush($push_id: String!) {
    removeScheduledPush(push_id: $push_id) {
      status
    }
  }
`;

class PushCampaign extends React.Component {
  constructor() {
    super();

    this.columns = [
      {
        Header: "Id",
        accessor: "id",
        // show: false
      },
      {
        id: "time",
        Header: "Time",
        accessor: "pushtime",
        width: 100,
        Cell: (row) => {
          // Tue Feb 20 2018 21:30:45 GMT+0100 (CET)
          const datetime = moment(row.value, "ddd MMM DD YYYY HH:mm:ss Z");
          return (
            <div className="datetime">
              <div className="date">{datetime.format("YYYY-MM-DD")}</div>
              <div className="time">{datetime.format("HH:mm:ss")}</div>
            </div>
          );
        },
      },
      {
        id: "title",
        Header: "Title",
        accessor: (d) => {
          const payload = JSON.parse(d.payload);
          if (payload && payload.alert && typeof payload.alert === "object") {
            return payload.alert.title || payload.title;
          } else {
            const alert = payload && payload.alert ? payload.alert : null;
            const title = payload && payload.title ? payload.title : null;
            return title || alert || "";
          }
        },
        Cell: (row) => <span title={row.value}>{row.value}</span>,
      },
      {
        id: "alert",
        Header: "Message",
        accessor: (d) => {
          const payload = JSON.parse(d.payload);
          if (payload && payload.alert && typeof payload.alert === "object") {
            return payload.alert.message || payload.message;
          } else {
            const title = payload && payload.title ? payload.title : null;
            const message = payload && payload.message ? payload.message : null;
            if (title) return message;
            else return "";
          }
        },
        Cell: (row) => <span title={row.value}>{row.value}</span>,
      },
      {
        id: "dashboard_audience",
        Header: "Audience",
        accessor: "payload",
        width: 150,
        Cell: (row) => {
          const value = JSON.parse(row.value);
          if (value.dashboardAudience) return value.dashboardAudience;
          else return "";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        width: 100,
        Cell: (row) => {
          let status = row.value;
          if (row.value.toLowerCase() === "succeeded") status = "Sent";
          else if (row.value.toLowerCase() === "pending") status = "Scheduled";
          else if (row.value.toLowerCase() === "running") status = "Sending";
          return <span title={status}>{status}</span>;
        },
      },
      {
        id: "devicetypes",
        Header: "Devices",
        accessor: "devicetypes",
        width: 100,
        Cell: (row) => {
          return row.value.map((devicetype) => {
            if (devicetype === "android")
              return (
                <img
                  key={devicetype}
                  className="device-icon"
                  src={android_icon}
                  alt=""
                />
              );
            else if (devicetype === "ios")
              return (
                <img
                  key={devicetype}
                  className="device-icon"
                  src={ios_icon}
                  alt=""
                />
              );
            else return <span key={devicetype}>{devicetype}</span>;
          });
        },
      },
      {
        id: "action",
        Header: "",
        Cell: (cellInfo) => {
          if (cellInfo.original.status !== "pending") return null;
          return (
            <a
              className="remove-message-button"
              href={null}
              onClick={(e) => {
                e.stopPropagation();
                this.handleClickDeleteMessage(cellInfo);
              }}
            >
              Delete
            </a>
          );
        },
        width: 150,
        style: { justifyContent: "center" },
      },
    ];

    this.state = {
      snackbarMessage: "",
      pushes: [],
      displayEdit: false,
    };
  }

  handleClickDeleteMessage = (cellInfo) => {
    this.setState({ snackbarMessage: "Deleting..." });
    this.props
      .removeScheduledPush({
        variables: {
          push_id: cellInfo.original.id,
        },
      })
      .then((data) => {
        this.setState({ snackbarMessage: "Deleted." });
        const pushes = this.state.pushes.filter(
          (push) => cellInfo.original.id !== push.id
        );
        this.setState({ pushes });
      })
      .catch((res) => {
        console.log(res);
        this.setState({ snackbarMessage: res.message });
      });
  };

  handleClickDeleteCampaign = () => {
    this.setState({ snackbarMessage: "Deleting..." });
    console.log("deleting campaign with id: ", this.props.match.params.id);
    this.props
      .removeCampaign({
        variables: {
          campaign_id: parseInt(this.props.match.params.id, 10),
        },
      })
      .then((data) => {
        this.setState({ snackbarMessage: "" });
        this.props.history.push("/push/campaigns");
      })
      .catch((res) => {
        console.log(res);
        this.setState({ snackbarMessage: res.message });
      });
  };

  handleSnackbarCloseRequest = () => {
    this.setState({ snackbarMessage: "" });
  };

  setSnackbarMessage = (message) => {
    this.setState({ snackbarMessage: message });
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.data.pushes) return;

    this.setState({
      pushes: [...nextProps.data.pushes],
    });
  }

  onRowClick = (state, rowInfo, column, instance) => {
    return {
      onClick: (e) => {
        this.props.history.push(`/push/activity/${rowInfo.row.id}`);
      },
    };
  };

  render() {
    // console.log(this.state);
    // console.log(this.props);
    if (this.props.data.loading) return <Loading />;
    if (this.props.data.error) {
      console.log(this.props.data.error);
      return <Loading />;
    }
    const { eventInfo } = this.props.data;

    return (
      <div className="PushCampaign">
        <MainHeader title="Edit campaign" />
        <MainContent>
          <div className="section">
            <div className="section-heading">
              {eventInfo.home_team} - {eventInfo.away_team}
            </div>
            <div className="section-subheading">
              {eventInfo.arena}, {eventInfo.day}{" "}
              {moment(eventInfo.timestamp).format("YYYY-MM-DD HH:mm")}
            </div>
            <div className="section-content">
              <ReactTable
                className="PD-table"
                data={this.state.pushes}
                loading={this.props.data.loading}
                columns={this.columns}
                getTrProps={this.onRowClick}
                loadingText="Loading campaigns..."
                noDataText={this.props.data.loading ? "" : ""}
                defaultSorted={[
                  {
                    id: "time",
                    desc: false,
                  },
                ]}
                showPagination={false}
                minRows={3}
              />
              <input
                type="submit"
                className={`delete-campaign-button ${
                  this.state.pushes && this.state.pushes.length > 0
                    ? "hidden"
                    : ""
                }`}
                value="Delete Campaign"
                onClick={this.handleClickDeleteCampaign}
              />
            </div>

            <input
              type="submit"
              className="show-hide-new-messages"
              value={
                this.state.displayEdit
                  ? "Hide adding new push messages"
                  : "Add new push messages"
              }
              onClick={() =>
                this.setState({ displayEdit: !this.state.displayEdit })
              }
            />
            {this.state.displayEdit ? (
              <PushCampaignMessages
                campaignId={parseInt(this.props.match.params.id, 10)}
                loading={false}
              />
            ) : null}
          </div>
        </MainContent>
        <Snackbar
          message={this.state.snackbarMessage}
          onRequestClose={this.handleSnackbarCloseRequest}
        />
      </div>
    );
  }
}

export default compose(
  graphql(eventInfoQuery, eventInfoQueryOptions),
  graphql(createCampaignMutation, createCampaignMutationOptions),
  graphql(removeScheduledPush, { name: "removeScheduledPush" }),
  graphql(removeCampaign, { name: "removeCampaign" })
)(PushCampaign);
