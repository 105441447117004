export const getLoggedInUser = () => {
  const defaultUser = {
    id: "",
    name: "",
    role: "",
    email: "",
    team: "",
    team_id: "",
    team_key: "",
    token: "",
    test_devices: [],
    app_identifiers: []
  };

  const storedUser = JSON.parse(localStorage.getItem("loggedInUser"));

  const loggedInUser = { ...defaultUser, ...storedUser };
  return loggedInUser;
};

export const updateLoggedInUser = (userData = {}) => {
  const currentUserData = JSON.parse(localStorage.getItem("loggedInUser"));
  const newUserData = { ...currentUserData, ...userData };
  localStorage.setItem("loggedInUser", JSON.stringify(newUserData));
  return newUserData;
};

export const unique_array = arr => {
  return [...new Set(arr)];
};

export const ucFirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8; // eslint-disable-line
    return v.toString(16);
  });
};
