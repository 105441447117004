import React from "react";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import { graphql } from "react-apollo";
import MainHeader from "../../components/Main/MainHeader";
import MainContent from "../../components/Main/MainContent";
import Snackbar from "../../components/Snackbar/Snackbar";
import Input from "../../components/Input/Input";
import Chips from "../../components/Chips/Chips";
import Loading from "../../components/Loading/Loading";
import Select from "../../components/Select/Select";
import {
  unique_array,
  updateLoggedInUser,
  getLoggedInUser,
} from "../../utils/utils";
import "./Account.scss";
import compose from "lodash.flowright";

const accountQuery = gql`
  query($id: Int!) {
    user(id: $id) {
      id
      name
      email
      team
      team_id
      team_key
      team_icon
      dry_mode
      league_key
      app_identifiers {
        key
        description
      }
      test_devices {
        installation_id
      }
    }
  }
`;

const accountQueryOptions = {
  options: (ownProps) => {
    return {
      variables: { id: parseInt(ownProps.match.params.id, 10) },
      fetchPolicy: "cache-and-network",
      skip: ownProps.match.params.id === "new",
    };
  },
};

const createAccountMutation = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      name
      email
      team
      team_id
      team_key
      team_icon
      dry_mode
      app_identifiers {
        key
        description
      }
    }
  }
`;

const updateAccountMutation = gql`
  mutation updateUser($user_id: Int!, $input: UpdateUserInput!) {
    updateUser(user_id: $user_id, input: $input) {
      id
      name
      email
      team
      team_id
      team_key
      team_icon
      dry_mode
      league_key
    }
  }
`;

const addAppIdentifierMutation = gql`
  mutation addAppIdentifier($user_id: Int!, $input: AppIdentifierInput!) {
    addAppIdentifier(user_id: $user_id, input: $input) {
      id
      app_identifiers {
        key
      }
    }
  }
`;

const removeAppIdentifierMutation = gql`
  mutation removeAppIdentifier($user_id: Int!, $key: String!) {
    removeAppIdentifier(user_id: $user_id, key: $key) {
      id
      app_identifiers {
        key
      }
    }
  }
`;

const addTestDeviceMutation = gql`
  mutation addTestDevice($user_id: Int!, $installation_id: String!) {
    addTestDevice(user_id: $user_id, installation_id: $installation_id) {
      id
      test_devices {
        installation_id
      }
    }
  }
`;

const removeTestDeviceMutation = gql`
  mutation removeTestDevice($user_id: Int!, $installation_id: String!) {
    removeTestDevice(user_id: $user_id, installation_id: $installation_id) {
      id
      test_devices {
        installation_id
      }
    }
  }
`;

class Account extends React.Component {
  constructor(props) {
    super(props);

    // id will be 'new' when creating user or actual id when updating user.
    let id = props.match.params.id !== 'new' ? parseInt(props.match.params.id, 10): 'new';

    this.state = {
      id: id,
      name: "",
      email: "",
      password: "",
      role: "user",
      team: "",
      team_id: null,
      team_key: "",
      team_icon: "",
      dry_mode: true,
      league_key: undefined,
      appIdentifiers: [],
      installation_ids: [],
      snackbarMessage: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.data.user) return;

    let newState = { ...nextProps.data.user };

    // Flatten 'appIdentifiers' array.
    const appIdentifiers = nextProps.data.user.app_identifiers || [];
    newState.appIdentifiers = unique_array(appIdentifiers.map((c) => c.key));

    // Flatten 'test_devices' array.
    const test_devices = nextProps.data.user.test_devices || [];
    newState.installation_ids = unique_array(
      test_devices.map((c) => c.installation_id)
    );

    this.setState(newState);
  }

  handleSnackbarCloseRequest = () => {
    this.setState({ snackbarMessage: "" });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ snackbarMessage: "Saving user..." });

    if (this.state.id === "new") {
      this.props
        .createUser({
          variables: {
            input: {
              name: this.state.name,
              email: this.state.email,
              password: this.state.password,
              role: this.state.role.toLowerCase(),
            },
          },
        })
        .then((res) => {
          this.setState({ snackbarMessage: "Saved." });
          this.props.history.replace(
            "/admin/accounts/" + res.data.createUser.id
          );
        })
        .catch((res) => {
          this.setState({ snackbarMessage: res.message });
        });
    } else {
      this.props
        .updateUser({
          variables: {
            user_id: this.state.id,
            input: {
              name: this.state.name,
              email: this.state.email,
              team: this.state.team || "",
              team_id:
                (this.state.team_id && parseInt(this.state.team_id, 10)) ||
                null,
              team_key: this.state.team_key || "",
              team_icon: this.state.team_icon || "",
              dry_mode: this.state.dry_mode,
              league_key: this.state.league_key || "",
            },
          },
        })
        .then((res) => {
          if (this.state.id === getLoggedInUser().id) {
            updateLoggedInUser(res.data.updateUser);
          }
          this.setState({ snackbarMessage: "Saved." });
        })
        .catch((res) => {
          this.setState({ snackbarMessage: res.message });
        });
    }
  };

  handleAddAppIdentifier = (appIdentifier) => {
    const app_identifiers = [...this.state.appIdentifiers, appIdentifier];
    this.setState({
      appIdentifiers: app_identifiers,
    });
    this.props
      .addAppIdentifier({
        variables: {
          user_id: this.state.id,
          input: {
            key: appIdentifier,
            description: "",
          },
        },
      })
      .then((res) => {
        if (this.state.id === getLoggedInUser().id)
          updateLoggedInUser({ app_identifiers });
      })
      .catch((res) => {
        console.log("catch", res);
      });
  };

  handleDeleteAppIdentifier = (appIdentifier) => {
    const appIdentifiers = this.state.appIdentifiers.filter(
      (aid) => aid !== appIdentifier
    );
    this.setState({ appIdentifiers });

    this.props
      .removeAppIdentifier({
        variables: {
          user_id: this.state.id,
          key: appIdentifier,
        },
      })
      .then((res) => {
        if (this.state.id === getLoggedInUser().id)
          updateLoggedInUser({ app_identifiers: appIdentifiers });
      })
      .catch((res) => {
        console.log("catch", res);
      });
  };

  handleAddInstallationId = (installation_id) => {
    const installation_ids = [...this.state.installation_ids, installation_id];
    this.setState({
      installation_ids,
    });

    this.props
      .addTestDevice({
        variables: {
          user_id: this.state.id,
          installation_id: installation_id,
        },
      })
      .then((res) => {
        if (this.state.id === getLoggedInUser().id)
          updateLoggedInUser({ test_devices: installation_ids });
      })
      .catch((res) => {
        console.log("catch", res);
      });
  };

  handleDeleteInstallationId = (installation_id) => {
    const installation_ids = this.state.installation_ids.filter(
      (iid) => iid !== installation_id
    );
    this.props
      .removeTestDevice({
        variables: {
          user_id: this.state.id,
          installation_id: installation_id,
        },
      })
      .then((res) => {
        if (this.state.id === getLoggedInUser().id)
          updateLoggedInUser({ test_devices: installation_ids });
      })
      .catch((res) => {
        console.log("catch", res);
      });

    this.setState({ installation_ids });
  };

  handleChangeRole = (e) => {
    this.setState({ role: e.target.value });
  };

  render() {
    if (this.props.data && this.props.data.loading) {
      return <Loading />;
    }

    return (
      <div className="Account">
        <MainHeader title="Account">
          <Link to="/admin/accounts" className="button">
            Go back
          </Link>
        </MainHeader>
        <MainContent>
          <div className="section">
            <div className="section-heading">
              {this.state.id === "new" ? "Create account" : "Account details"}
            </div>
            <div className="section-content">
              <form
                name="account-form"
                className="account-form"
                onSubmit={this.onSubmit}
              >
                <div className="input-box">
                  <Input
                    heading="id"
                    type="text"
                    name="id"
                    value={this.state.id}
                    readOnly
                  />
                  <Input
                    heading="Name"
                    type="text"
                    name="name"
                    value={this.state.name || ""}
                    onChange={this.handleInputChange}
                    autoComplete="name"
                    required
                  />
                  <Input
                    heading="Username"
                    type="name"
                    name="email"
                    value={this.state.email || ""}
                    onChange={this.handleInputChange}
                    autoComplete="name"
                    disabled={this.state.id !== "new"}
                    required
                  />
                  <Input
                    heading="Password"
                    type="password"
                    name="password"
                    value={this.state.password || ""}
                    onChange={this.handleInputChange}
                    autoComplete="current-password"
                    render={this.state.id === "new"}
                    placeholder="***"
                    pattern=".{8,20}"
                    title="Password must be 8 to 20 characters."
                    required
                  />
                  <Select
                    heading="Role"
                    name="role"
                    options={[
                      { value: "admin", label: "Admin" },
                      { value: "user", label: "User" },
                    ]}
                    value={this.state.role}
                    onChange={this.handleInputChange}
                    render={this.state.id === "new"}
                  />
                  <Input
                    heading="Team"
                    type="text"
                    name="team"
                    value={this.state.team || ""}
                    onChange={this.handleInputChange}
                    placeholder="Team name"
                    render={this.state.id !== "new"}
                  />
                  <Input
                    heading="Team Id"
                    type="text"
                    name="team_id"
                    value={this.state.team_id || ""}
                    onChange={this.handleInputChange}
                    pattern="\d+"
                    placeholder="A number that id's the team"
                    render={this.state.id !== "new"}
                  />
                  <Input
                    heading="Team key"
                    type="text"
                    name="team_key"
                    value={this.state.team_key || ""}
                    onChange={this.handleInputChange}
                    render={this.state.id !== "new"}
                  />
                  <Input
                    heading="Team icon"
                    type="url"
                    name="team_icon"
                    value={this.state.team_icon || ""}
                    onChange={this.handleInputChange}
                    render={this.state.id !== "new"}
                    placeholder="URL to icon"
                  />
                  <Select
                    heading="League identifier"
                    name="league_key"
                    options={[
                      { value: "", label: "None selected" },
                      { value: "allsvenskan", label: "Allsvenskan" },
                      { value: "superettan", label: "Superettan" },
                      { value: "eliteserien", label: "Eliteserien" },
                      { value: "obos-ligaen", label: "Obos-ligaen" },
                    ]}
                    value={this.state.league_key || undefined}
                    onChange={this.handleInputChange}
                    render={this.state.id !== "new"}
                  />
                  <Input
                    heading="Dry mode for push message"
                    type="checkbox"
                    name="dry_mode"
                    checked={this.state.dry_mode}
                    onChange={() => {
                      this.setState({ dry_mode: !this.state.dry_mode });
                    }}
                    render={this.state.id !== "new"}
                  />
                  <Chips
                    className="test-devices"
                    heading="Test devices"
                    chips={this.state.installation_ids}
                    onDeleteChip={this.handleDeleteInstallationId}
                    onAddChip={this.handleAddInstallationId}
                    placeholder="Add installation id"
                    shouldRender={this.state.id !== "new"}
                    pattern=".{8}-.{4}-.{4}-.{4}-.{12}"
                    title="Format: aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee"
                  />
                  <Chips
                    className="app-identifiers"
                    heading="App identifiers"
                    chips={this.state.appIdentifiers}
                    onDeleteChip={this.handleDeleteAppIdentifier}
                    onAddChip={this.handleAddAppIdentifier}
                    placeholder="Add app identifier"
                    shouldRender={this.state.id !== "new"}
                  />
                </div>
                <Input
                  type="submit"
                  className="button"
                  value="Save"
                  disabled={this.state.snackbarMessage}
                />
              </form>
            </div>
          </div>
        </MainContent>
        <Snackbar
          message={this.state.snackbarMessage}
          onRequestClose={this.handleSnackbarCloseRequest}
        />
      </div>
    );
  }
}

const AccountWithData = compose(
  graphql(accountQuery, accountQueryOptions),
  graphql(createAccountMutation, { name: "createUser" }),
  graphql(updateAccountMutation, { name: "updateUser" }),
  graphql(addAppIdentifierMutation, { name: "addAppIdentifier" }),
  graphql(removeAppIdentifierMutation, { name: "removeAppIdentifier" }),
  graphql(addTestDeviceMutation, { name: "addTestDevice" }),
  graphql(removeTestDeviceMutation, { name: "removeTestDevice" })
)(Account);

export default AccountWithData;
