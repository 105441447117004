import React from "react";
import md5 from "md5";
import "./Gravatar.scss";

class Gravatar extends React.Component {
  render() {
    const email = this.props.email || "";
    return (
      <div className="Gravatar">
        <img
          src={`//www.gravatar.com/avatar/${md5(email)}?d=mm`}
          alt="Gravatar"
          className="gravatar-img"
        />
      </div>
    );
  }
}

export default Gravatar;
