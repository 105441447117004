import React from "react";
import { graphql } from "react-apollo";
import { withRouter } from "react-router";
import gql from "graphql-tag";
import moment from "moment";
import isUrl from "is-url";
import Modal from "../../components/Modal/Modal";
import "./PushCampaigns.scss";
import compose from "lodash.flowright";

const sendPushMutationOption = {
  name: "sendPush",
};

const sendPushMutation = gql`
  mutation sendPush($input: SendPushInput!) {
    sendPush(input: $input) {
      status
    }
  }
`;

const createCampaignMutationOptions = {
  name: "createCampaign",
  options: (ownProps) => {
    return {
      variables: { event_id: parseInt(ownProps.match.params.id, 10) },
    };
  },
};

const createCampaignMutation = gql`
  mutation createCampaign($event_id: Int!) {
    createCampaign(event_id: $event_id) {
      id
    }
  }
`;

class PushCampaignSendButton extends React.Component {
  constructor() {
    super();
    this.state = {
      modalMessage: "",
    };
  }
  validatePushData = (pushData) => {
    const {
      title,
      audience,
      // message,
      article_url,
      send_date,
      send_time,
    } = pushData;

    if (!send_date || !moment(send_date).isValid()) return "Date is invalid.";
    if (!send_time || !moment(send_time, "HH:mm").isValid()) {
      return "Time is invalid.";
    }
    const yesterday = moment().subtract(1, "day");
    if (!moment(`${send_date} ${send_time}`).isAfter(yesterday)) {
      return "Date and time is not in the future.";
    }

    if (!title || title.length === 0) return "Title is invalid.";
    if (!isUrl(article_url)) {
      return "Article URL is invalid.";
    }
    if (!audience || audience.length === 0) return "Audience is invalid.";

    return true;
  };

  handleSendCampaignItem = () => {
    console.log(this.props.campaignMessages);
    this.props.disableSendButtons(true);

    const sendingText = this.props.sendTestMessages
      ? "Sending..."
      : "Publishing...";

    // Clean messages and sort by date.
    const messages = [...this.props.campaignMessages]
      .map((msg) => {
        return {
          ...msg,
          moment: moment(`${msg.send_date} ${msg.send_time}`),
          article_url: msg.article_url.trim(),
        };
      })
      .sort((a, b) => a.moment > b.moment);

    this.props.snackbarMessage(sendingText);

    // Validate.
    if (!messages || !Array.isArray(messages) || messages.length < 1) {
      this.props.snackbarMessage("Please add messages.");
      this.props.disableSendButtons(false);
      return;
    }

    let validation = false;
    for (var i = 0; i < messages.length; i++) {
      validation = this.validatePushData(messages[i]);
      if (validation !== true) {
        this.props.snackbarMessage(validation);
        break;
      }
    }

    if (validation !== true) {
      this.props.disableSendButtons(false);
      return;
    }

    if (this.props.sendTestMessages) {
      this.sendTestMessages(messages);
    } else {
      this.publishMessages(messages);
    }
  };

  sendTestMessages = (messages) => {
    // Send.
    const messagesPromises = [];
    messages.forEach((msg) => {
      const { title, message, article_url, send_date, send_time } = msg;
      const send_now = true;
      const audience = "my-test-users";

      this.props.snackbarMessage("Sending...");

      const messagePromise = this.props.sendPush({
        variables: {
          input: {
            title,
            audience,
            message,
            article_url,
            send_now,
            send_date,
            send_time,
          },
        },
      });

      messagesPromises.push(messagePromise);
    });

    Promise.all(messagesPromises)
      .then((data) => {
        this.props.disableSendButtons(false);
        this.props.snackbarMessage("Sent.");
      })
      .catch((res) => {
        console.log(res);
        this.props.snackbarMessage(res.message);
      });
  };

  createCampaign = async () => {
    try {
      const campaign = await this.props.createCampaign();
      return campaign.data.createCampaign.id;
    } catch (e) {
      this.props.disableSendButtons(false);
      this.props.snackbarMessage(e.message);
      return;
    }
  };

  publishMessages = async (messages) => {
    // Create a campaign and then send/publish.
    const campaign_id = this.props.campaignId
      ? this.props.campaignId
      : await this.createCampaign();
    // Send.
    const messagesPromises = [];
    messages.forEach((msg) => {
      const {
        title,
        audience,
        message,
        article_url,
        send_date,
        send_time,
      } = msg;

      let send_now = false;

      this.props.snackbarMessage("Publishing...");

      const messagePromise = this.props.sendPush({
        variables: {
          input: {
            title,
            audience,
            message,
            article_url,
            send_now,
            send_date,
            send_time,
            campaign_id,
          },
        },
      });

      messagesPromises.push(messagePromise);
      // new Promise((resolve, reject) => {
      //   setTimeout(reject, 1000);
      // })
    });
    Promise.all(messagesPromises)
      .then((data) => {
        this.props.snackbarMessage("");
        this.setState({
          modalMessage: "Messages published. Now syncing, please wait...",
        });
      })
      .catch((res) => {
        console.log(res);
        this.props.disableSendButtons(false);
        this.props.snackbarMessage(res.message);
      });
  };

  handleModalTimeout = () => {
    this.setState({ modalMessage: "" });
    this.props.history.replace({
      pathname: `/push/campaigns/`,
      // pathname: `/push/campaigns/${campaign_id}`,
      // search: `?eventId=${this.props.match.params.id}`
    });
  };

  render() {
    return (
      <div className={`PushCampaignSendButton ${this.props.className}`}>
        <input
          className="send-push-button"
          type="submit"
          onClick={this.handleSendCampaignItem}
          value={this.props.buttonText}
          disabled={this.props.disabled}
        />
        <Modal
          message={this.state.modalMessage}
          timeout={10000}
          onTimeout={this.handleModalTimeout}
        />
      </div>
    );
  }
}

export default withRouter(
  compose(
    graphql(sendPushMutation, sendPushMutationOption),
    graphql(createCampaignMutation, createCampaignMutationOptions)
  )(PushCampaignSendButton)
);
