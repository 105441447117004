import React from "react";
import ReactTable from "react-table";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import moment from "moment";
import MainHeader from "../../components/Main/MainHeader";
import MainContent from "../../components/Main/MainContent";
import "./PushCampaigns.scss";

const scheduledEventsQuery = gql`
  query {
    scheduledEvents {
      id
      start_date
      home_team
      away_team
      campaign_id
    }
  }
`;

const columns = [
  {
    Header: "Id",
    accessor: "id",
    width: 150
  },
  {
    id: "date",
    Header: "Date",
    accessor: d => {
      const datetime = moment(d.start_date);
      return (
        <div className="datetime">
          <div className="date">{datetime.format("YYYY-MM-DD")}</div>
          <div className="time">{datetime.format("HH:mm:ss")}</div>
        </div>
      );
    },
    width: 100
  },
  {
    id: "match",
    Header: "Match",
    accessor: d => {
      return (
        <span>
          {d.home_team}-{d.away_team}
        </span>
      );
    }
  },
  {
    id: "campaignLink",
    Header: "",
    accessor: d => {
      const now = moment();
      // const inTwoWeeks = moment().add(13, "day");
      const inTwoWeeks = moment().add(93, "day");
      const start_date = moment(d.start_date);
      if (start_date.isAfter(inTwoWeeks)) {
        return "";
      }

      if (start_date.isBefore(now)) {
        return "Event's passed.";
      }

      const path = d.campaign_id ? `${d.campaign_id}` : `setup/${d.id}`;
      const query = d.campaign_id ? `?eventId=${d.id}` : "";
      const linkText = d.campaign_id ? "Edit" : "Setup";

      return (
        <Link
          to={{
            pathname: `/push/campaigns/${path}`,
            search: `${query}`
          }}
          className="link-to-campaign"
        >
          {linkText}
        </Link>
      );
    },
    width: 180
  }
];

class PushCampaigns extends React.Component {
  render() {
    return (
      <div className="PushCampaigns">
        <MainHeader title="Campaigns" />
        <MainContent>
          <div className="late-events">* Campaigns cannot be created for events that will start more than 2 weeks in the future.</div>
          <ReactTable
            className="PD-table -highlight"
            data={this.props.data.scheduledEvents || []}
            loading={this.props.data.loading}
            columns={columns}
            loadingText="Loading matches..."
            noDataText={this.props.data.loading ? "" : "No matches found."}
            defaultSorted={[
              {
                id: "date",
                desc: false
              }
            ]}
            showPagination={false}
          />
        </MainContent>
      </div>
    );
  }
}

export default graphql(scheduledEventsQuery, {
  options: () => ({
    fetchPolicy: "network-only"
  })
})(PushCampaigns);
