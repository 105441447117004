import React from "react";
import DateTimePicker from "react-datetime";
import Input from "../../components/Input/Input";
import "./PushScheduler.scss";

class Scheduler extends React.Component {
  constructor() {
    super();
    this.state = {
      sendNow: true
    };
  }
  componentDidMount() {
    // DateTimePicker component does not allow disabling switching
    // to month/year view, so we take away the click event here.
    // const rdtSwitch = document.querySelector(".rdtSwitch");
    // const newRdtSwitch = rdtSwitch.cloneNode(true);
    // rdtSwitch.parentNode.replaceChild(newRdtSwitch, rdtSwitch);
  }

  handleOnChangeSendNow = () => {
    const sendNow = !this.state.sendNow;
    this.setState({ sendNow });
    if (this.props.onSendNowChange) this.props.onSendNowChange(sendNow);
  };

  isValidDate(current) {
    const twoWeeksFromNow = DateTimePicker.moment().add(13, "day");
    const yesterday = DateTimePicker.moment().subtract(1, "day");
    return current.isBefore(twoWeeksFromNow) && current.isAfter(yesterday);
  }

  render() {
    return (
      <div className="Scheduler input-box">
        <div className="send-now">
          <div className="send-now-text">Send immediately?</div>
          <Input
            type="checkbox"
            className="send-now-input"
            onChange={this.handleOnChangeSendNow}
            checked={this.state.sendNow}
          />
        </div>
        <hr className={this.state.sendNow ? "hidden" : ""} />
        <div className={`send-later ${this.state.sendNow ? "hidden" : ""}`}>
          <div className="send-later-text">When should we deliver this?</div>
          <DateTimePicker
            name="date-picker"
            className="date-picker"
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
            inputProps={{
              placeholder: "YYYY-MM-DD",
              name: "schedulerdate",
              required: !this.state.sendNow,
              onChange: () => {
                // Remove the ability to type the date...
              }
            }}
            onChange={this.props.onDateChange}
            isValidDate={this.isValidDate}
          />
          <DateTimePicker
            className="time-picker"
            dateFormat={false}
            timeFormat={"HH:mm"}
            inputProps={{
              placeholder: "HH:mm",
              name: "schedulertime",
              pattern: "([0-9]|[0-1][0-9]|2[2-3]):[0-5][0-9]$",
              required: !this.state.sendNow
            }}
            onChange={this.props.onTimeChange}
          />
        </div>
      </div>
    );
  }
}

export default Scheduler;
