import React from "react";
import "./Snackbar.scss";

class Snackbar extends React.Component {
  constructor() {
    super();
    this.timer = null;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.message) {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.props.onRequestClose, 3000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const message = this.props.message;
    return (
      <div className={`Snackbar ${message ? "" : "hidden"}`}>
        {this.props.message}
      </div>
    );
  }
}

export default Snackbar;
