import React, { Component } from "react";
import Gravatar from "../../components/Gravatar/Gravatar";
import { withApollo } from "react-apollo";
import { NavLink, Link } from "react-router-dom";
import { withRouter, matchPath } from "react-router";

import "./Sidebar.scss";

class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      isUserMenuVisible: false,
      loggedInUser: null
    };
  }
  toggleUserMenu = () => {
    this.setState({ isUserMenuVisible: !this.state.isUserMenuVisible });
  };
  componentWillMount() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    this.setState({ loggedInUser });
  }
  render() {
    const { pathname } = this.props.location;
    return (
      <div className="Sidebar">
        <header>
          <h1>Push dashboard</h1>
        </header>
        <div className="menu">
          {/* Push menu */}
          <div
            className={`menu-section ${
              matchPath(pathname, { path: "/push" }) ? "active" : ""
            }`}
          >
            <NavLink
              className="menu-heading"
              activeClassName="active-link"
              to="/push/"
            >
              Push
            </NavLink>
            <ul className="menu-items">
              <li>
                <NavLink
                  className="menu-item"
                  activeClassName="active-link"
                  to="/push/activity"
                >
                  Activity
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="menu-item"
                  activeClassName="active-link"
                  to="/push/send"
                >
                  Send new push
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="menu-item"
                  activeClassName="active-link"
                  to="/push/campaigns"
                >
                  Campaigns
                </NavLink>
              </li>
            </ul>
          </div>

          {/* Settings */}

          <div
            className={`menu-section settings-menu
              ${matchPath(pathname, { path: "/settings" }) ? "active" : ""}`}
          >
            <NavLink
              className="menu-heading"
              activeClassName="active-link"
              to={`/settings/account/${this.state.loggedInUser.id}`}
            >
              Settings
            </NavLink>
            <ul className="menu-items">
              <li>
                <NavLink
                  className="menu-item"
                  activeClassName="active-link"
                  to={`/settings/account/${this.state.loggedInUser.id}`}
                >
                  My Account
                </NavLink>
              </li>
            </ul>
          </div>

          {/* Admin menu */}

          <div
            className={`menu-section admin-menu
              ${matchPath(pathname, { path: "/admin" }) ? "active" : ""}
              ${this.state.loggedInUser.role === "admin" ? "visible" : ""}`}
          >
            <NavLink
              className="menu-heading"
              activeClassName="active-link"
              to="/admin"
            >
              Admin
            </NavLink>
            <ul className="menu-items">
              <li>
                <NavLink
                  className="menu-item"
                  activeClassName="active-link"
                  to="/admin/accounts"
                >
                  Accounts
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <footer onClick={this.toggleUserMenu}>
          <div
            className={`user-menu input-box ${
              this.state.isUserMenuVisible ? "visible" : ""
            }`}
          >
            <ul>
              <li className="user-menu-li">
                <Link
                  onClick={() => this.props.client.resetStore()}
                  to="/login"
                >
                  Log out
                </Link>
              </li>
            </ul>
            <div className="arrow" />
          </div>
          <Gravatar email={this.state.loggedInUser.email} />
          <a className="email">{this.state.loggedInUser.email}</a>
        </footer>
      </div>
    );
  }
}

export default withApollo(withRouter(Sidebar));
