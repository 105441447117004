import React from "react";
import loadingImage from "../../assets/images/loading.svg";
import "./Loading.scss";

const Loading = () => {
  return (
    <div className="Loading">
      <img src={loadingImage} alt="loading" className="loading-image" />
    </div>
  );
};

export default Loading;
