import React from "react";
import gql from "graphql-tag";
import ReactTable from "react-table";
import { graphql, withApollo } from "react-apollo";
import moment from "moment";
import MainHeader from "../../components/Main/MainHeader";
import MainContent from "../../components/Main/MainContent";
import Snackbar from "../../components/Snackbar/Snackbar";
import android_icon from "../../assets/images/android.svg";
import ios_icon from "../../assets/images/ios.svg";
import compose from "lodash.flowright";
import "./PushActivity.scss";

const pushActivityQuery = gql`
  query PushActivityQuery($limit: Int, $offset: Int) {
    pushes(limit: $limit, offset: $offset) {
      id
      payload
      pushtime
      query
      status
      numsent
      numfailed
      devicetypes
      dashboard_audience
    }
  }
`;

const query = gql`
  query MyTodoAppQuery {
    pageInfo {
      page
    }
  }
`;

const removeScheduledPush = gql`
  mutation removeScheduledPush($push_id: String!) {
    removeScheduledPush(push_id: $push_id) {
      status
    }
  }
`;

class PushActivityContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      snackbarMessage: "",
      pages: 20,
      page: 0,
    };
    this.columns = [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },
      {
        id: "time",
        Header: "Time",
        accessor: "pushtime",
        width: 100,
        Cell: (row) => {
          // Tue Feb 20 2018 21:30:45 GMT+0100 (CET)
          const datetime = moment(row.value, "x");
          return (
            <div className="datetime">
              <div className="date">{datetime.format("YYYY-MM-DD")}</div>
              <div className="time">{datetime.format("HH:mm:ss")}</div>
            </div>
          );
        },
      },
      {
        id: "alert",
        Header: "Message",
        accessor: (d) => {
          const payload = JSON.parse(d.payload);

          if (payload && payload.alert && typeof payload.alert === "object") {
            const title = payload.alert.title || payload.title;
            const message = payload.alert.body || payload.message;
            return title || message || "";
          } else {
            const alert = payload && payload.alert ? payload.alert : null;
            const title = payload && payload.title ? payload.title : null;
            const message = payload && payload.message ? payload.message : null;
            return title || alert || message || "";
          }
        },
        Cell: (row) => <span title={row.value}>{row.value}</span>,
      },
      {
        id: "dashboard_audience",
        Header: "Audience",
        accessor: "dashboard_audience",
        width: 150,
        Cell: (row) => <span title={row.value}>{row.value}</span>,
      },
      {
        Header: "Status",
        accessor: "status",
        width: 100,
        Cell: (row) => {
          let status = row.value;
          if (row.value.toLowerCase() === "succeeded") status = "Sent";
          else if (row.value.toLowerCase() === "pending") status = "Scheduled";
          else if (row.value.toLowerCase() === "running") status = "Sending";
          return <span title={status}>{status}</span>;
        },
      },
      {
        id: "devicetypes",
        Header: "Devices",
        accessor: "devicetypes",
        width: 100,
        Cell: (row) => {
          return row.value.map((devicetype) => {
            if (devicetype === "android")
              return (
                <img
                  key={devicetype}
                  className="device-icon"
                  src={android_icon}
                  alt=""
                />
              );
            else if (devicetype === "ios")
              return (
                <img
                  key={devicetype}
                  className="device-icon"
                  src={ios_icon}
                  alt=""
                />
              );
            else return <span key={devicetype}>{devicetype}</span>;
          });
        },
      },
      {
        id: "sentOfTotal",
        Header: "Sent of total",
        accessor: (d) => {
          return `${d.numsent} / ${d.numsent + d.numfailed}`;
        },
        Cell: (row) => <span>{row.value}</span>,
        width: 120,
      },
      {
        id: "action",
        Header: "",
        Cell: (cellInfo) => {
          if (cellInfo.original.status !== "pending") return null;
          return (
            <a
              className="remove-message-button"
              href={null}
              onClick={(e) => {
                e.stopPropagation();
                this.handleClickDeleteMessage(e, cellInfo);
              }}
            >
              Delete
            </a>
          );
        },
        width: 80,
      },
    ];
  }

  handleClickDeleteMessage = (e, cellInfo) => {
    this.setState({ snackbarMessage: "Deleting..." });
    console.log("deleting push with id: ", cellInfo.original.id);
    const rowElement = e.target.closest(".rt-tr-group");
    this.props
      .removeScheduledPush({
        variables: {
          push_id: cellInfo.original.id,
        },
      })
      .then((data) => {
        rowElement.classList.add("hidden");
        this.setState({ snackbarMessage: "Deleted." });
      })
      .catch((res) => {
        console.log(res);
        this.setState({ snackbarMessage: res.message });
      });
  };

  handleSnackbarCloseRequest = () => {
    this.setState({ snackbarMessage: "" });
  };

  render() {
    const offset = this.props.match.params.offset
      ? parseInt(this.props.match.params.offset, 10)
      : 0;
    console.log("here");
    return (
      <div className="PushActivity">
        <MainHeader title="Activity" />
        <MainContent>
          <ReactTable
            className="PD-table -highlight"
            data={this.props.data.pushes || []}
            loading={this.props.data.loading}
            columns={this.columns}
            loadingText="Loading activities..."
            noDataText={this.props.data.loading ? "" : "No activity found."}
            defaultPageSize={20}
            showPageSizeOptions={false}
            defaultSorted={[
              {
                id: "time",
                desc: true,
              },
            ]}
            sortable={false}
            getTrProps={this.onRowClick}
            showPagination={false}
            manual
            ofText=""
          />
          <div className="navigation">
            <input
              className="navigation-prev"
              type="submit"
              onClick={() => {
                this.props.history.push(`/push/activities/${offset - 1}`);
              }}
              value="Prev"
              disabled={offset < 1}
            />
            <div className="navigation-page">{offset + 1}</div>
            <input
              className="navigation-next"
              type="submit"
              onClick={() => {
                this.props.history.push(`/push/activities/${offset + 1}`);
              }}
              value="Next"
              disabled={
                this.props.data.pushes && this.props.data.pushes.length < 20
              }
            />
          </div>
        </MainContent>
        <Snackbar
          message={this.state.snackbarMessage}
          onRequestClose={this.handleSnackbarCloseRequest}
        />
      </div>
    );
  }

  fetchData = (state, instance) => {
    this.props.data.fetchMore({
      variables: {
        offset: state.page * state.pageSize,
        limit: state.pageSize,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        if (fetchMoreResult.pushes.length < state.pageSize) {
          this.setState({ pages: state.page + 1 });
        }
        return {
          pushes: fetchMoreResult.pushes,
        };
      },
    });
  };

  onRowClick = (state, rowInfo, column, instance) => {
    console.log("row info", rowInfo);
    return {
      onClick: (e) => {
        this.props.client.writeQuery({
          query,
          data: {
            pageInfo: {
              page: state.page,
              pageSize: state.pageSize,
              __typename: "PageInfo",
            },
          },
        });

        this.props.history.push(`/push/activity/${rowInfo.row.id}`);
      },
    };
  };
}

export default compose(
  graphql(removeScheduledPush, { name: "removeScheduledPush" }),
  graphql(pushActivityQuery, {
    options: (ownProps) => {
      return {
        variables: {
          offset:
            (ownProps.match.params.offset &&
              parseInt(ownProps.match.params.offset, 10) * 20) ||
            0,
          limit: 20,
        },
      };
    },
  }),
  withApollo
)(PushActivityContainer);

//export default PushActivity;
