import React from "react";
import "./Input.scss";

class Input extends React.Component {
  render() {
    const {
      render = true,
      className = "",
      heading,
      label,
      infoText,
      ...inputparams
    } = this.props;

    if (!render) return null;

    const heading_el = heading ? (
      <div className="heading">{heading}</div>
    ) : null;

    const label_el =
      label && inputparams.id ? (
        <label
          className={inputparams.disabled ? "disabled" : ""}
          htmlFor={inputparams.id}
        >
          {label}
        </label>
      ) : null;

    return (
      <div className={`Input ${className}`}>
        {heading_el}
        <input {...inputparams} />
        {label_el}
        <div className="infotext">{infoText}</div>
      </div>
    );
  }
}

export default Input;
