import React from "react";
import "./MainHeader.scss";

class MainHeader extends React.Component {
  render() {
    return (
      <header className="MainHeader">
        <h1 className="Main-title">{this.props.title}</h1>
        {this.props.children}
      </header>
    );
  }
}

export default MainHeader;
