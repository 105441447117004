import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Main from "../Main/Main";
import "./Layout.scss";

class Layout extends React.Component {
  componentWillMount() {
    const isLoggedIn = localStorage.getItem("loggedInUser");
    this.setState({ isLoggedIn });
    if (!isLoggedIn) {
      this.props.history.push("/login");
    }
  }
  render() {
    // Redirected to 'Login' page already in componentWillMount, so just return.
    if (!this.state.isLoggedIn) return "Not logged in.";

    return (
      <div className="Layout">
        <Sidebar />
        <Main />
      </div>
    );
  }
}

export default Layout;
