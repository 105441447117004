import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "react-table/react-table.css";
import "./Main.scss";
import MyAccountWithData from "../../components/Accounts/MyAccount.js";
import AccountWithData from "../../components/Accounts/Account.js";
import AccountsWithData from "../../components/Accounts/Accounts.js";
import PushActivity from "../../components/Push/PushActivity.js";
import PushDetailsWithData from "../../components/Push/PushDetails.js";
import PushSend from "../../components/Push/PushSend.js";
import PushCampaigns from "../../components/Push/PushCampaigns.js";
import PushCampaignSetup from "../../components/Push/PushCampaignSetup.js";
import PushCampaign from "../../components/Push/PushCampaign.js";
import { getLoggedInUser } from "../../utils/utils";

class Main extends Component {
  render() {
    return (
      <div className="Main">
        <Switch>
          {/* Push */}

          <Redirect exact from="/" to="/push/activity" />
          <AuthRoute
            path="/push/activity/:id"
            roles={["user", "admin"]}
            component={PushDetailsWithData}
          />
          <AuthRoute
            path="/push/activity"
            roles={["user", "admin"]}
            component={PushActivity}
          />
          <AuthRoute
            path="/push/activities/:offset"
            roles={["user", "admin"]}
            component={PushActivity}
          />
          <AuthRoute
            path="/push/send"
            roles={["user", "admin"]}
            component={PushSend}
          />
          <AuthRoute
            path="/push/campaigns/setup/:id"
            roles={["user", "admin"]}
            component={PushCampaignSetup}
          />
          <AuthRoute
            path="/push/campaigns/:id"
            roles={["user", "admin"]}
            component={PushCampaign}
          />
          <AuthRoute
            path="/push/campaigns"
            roles={["user", "admin"]}
            component={PushCampaigns}
          />
          <Redirect from="/push" to="/push/activity" />

          {/* Settings */}

          <AuthRoute
            path="/settings/account/:id"
            roles={["user", "admin"]}
            component={MyAccountWithData}
          />

          {/* Admin */}

          <AuthRoute
            path="/admin/accounts/:id"
            roles={["admin"]}
            component={AccountWithData}
          />
          <AuthRoute
            path="/admin/accounts"
            roles={["admin"]}
            component={AccountsWithData}
          />
          <Redirect from="/admin" to="/admin/accounts" />
          <Redirect from="*" to="/" />
        </Switch>
      </div>
    );
  }
}

export default Main;

class AuthRoute extends React.Component {
  render() {
    const loggedInUser = getLoggedInUser();
    const { component: Component, roles = [], ...rest } = this.props;
    const isAllowedUser = roles.includes(loggedInUser.role);
    return (
      // Render the component if user is allowed, otherwise redirect to /login.
      <Route
        {...rest}
        render={(props) =>
          isAllowedUser ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    );
  }
}
