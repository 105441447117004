import React from "react";
import gql from "graphql-tag";
import { graphql, withApollo } from "react-apollo";
import Input from "../../components/Input/Input";
import "./Login.scss";

const loginMutation = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        name
        email
        team
        team_id
        team_key
        team_icon
        dry_mode
        app_identifiers {
          key
          description
        }
        test_devices {
          installation_id
        }
      }
      token
      role
    }
  }
`;

const loginMutationOptions = {
  options: {
    fetchPolicy: "no-cache"
  }
};

class Login extends React.Component {
  constructor() {
    super();
    localStorage.removeItem("loggedInUser");
    this.errorMessageTimeout = null;
    this.state = {
      email: "",
      password: "",
      errorMessage: "",
      isErrorMessageVisible: false
    };
  }

  onSubmit = e => {
    e.preventDefault();

    const { email, password } = this.state;

    this.props
      .mutate({
        variables: { email, password }
      })
      .then(res => {
        // Save logged in user in localStorage.
        // User's token will be used in all graphql requests.
        const loggedInUser = {
          ...res.data.login.user,
          role: res.data.login.role || "user",
          token: res.data.login.token
        };
        localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));

        // User is logged in so go to app.
        this.props.history.push("/");
      })
      .catch(res => {
        // Login failed, flash error message.
        res.graphQLErrors &&
          res.graphQLErrors.map(error => {
            this.setState({
              errorMessage: "Login failed",
              isErrorMessageVisible: true
            });
            clearTimeout(this.errorMessageTimeout);
            this.errorMessageTimeout = setTimeout(
              () => this.setState({ isErrorMessageVisible: false }),
              2000
            );
            return error.message;
          });
      });
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div className="Login">
        <div className="content">
          <div className="logo">Push Dashboard</div>
          <form className="input-box" onSubmit={this.onSubmit}>
            <Input
              type="text"
              name="email"
              placeholder="Email or username"
              autoComplete="email"
              value={this.state.email}
              onChange={this.handleInputChange}
              required
            />
            <Input
              type="password"
              name="password"
              placeholder="Password"
              autoComplete="current-password"
              value={this.state.password}
              onChange={this.handleInputChange}
              required
            />
            <Input type="submit" value="Login" />
            <div
              className={`error-message ${
                this.state.isErrorMessageVisible ? "visible" : ""
              }`}
            >
              {this.state.errorMessage}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const LoginWithData = graphql(loginMutation, loginMutationOptions)(Login);
export default withApollo(LoginWithData);
