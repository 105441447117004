import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import MainHeader from "../../components/Main/MainHeader";
import MainContent from "../../components/Main/MainContent";
import { unique_array } from "../../utils/utils";
import "./Accounts.scss";

const accountsQuery = gql`
  query($limit: Int, $offset: Int) {
    users(limit: $limit, offset: $offset) {
      id
      name
      email
      team
      team_id
      team_key
      app_identifiers {
        key
        description
      }
    }
  }
`;

class Accounts extends React.Component {
  constructor() {
    super();
    this.columns = [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },
      {
        id: "name",
        Header: "Name",
        accessor: "name",
      },
      {
        id: "email",
        Header: "Email",
        accessor: "email",
      },
      {
        id: "team",
        Header: "Team",
        accessor: (d) => {
          return (
            <div>
              <div className="team-name">{d.team}</div>
              <div className="team-key-id">
                {d.team_id ? <span>Id: {d.team_id}. </span> : null}
                {d.team_key ? <span>Key: {d.team_key}</span> : null}
              </div>
            </div>
          );
        },
      },
      {
        id: "app_identifiers",
        Header: "App identifier",
        accessor: (d) => {
          const appIdKeys = unique_array(
            d.app_identifiers.map((app_id) => app_id.key)
          );

          return appIdKeys.map((app_id) => {
            return (
              <div className="app-id-key" key={app_id}>
                {app_id}
              </div>
            );
          });
        },
      },
    ];
  }

  fetchData = (state, instance) => {
    // console.log("state", state);
    // this.setState({ page: state.page });
    // console.log("instance", instance);
    // console.log("props: ", this.props);

    // console.log("pageInfo:", pageInfoData);
    // console.log("page:", page);

    // const data = this.props.client.readQuery({ query });
    // console.log("readdata", data);

    // this.props.client.readQuery({
    //   activityPageQuery
    // });
    // if (this.props.activityPage) {
    //   this.props.activityPage({}).then(res => {
    //     console.log("then ", res).catch(res => {
    //       console.log(res);
    //     });
    //   });
    // }
    this.props.data.fetchMore({
      variables: {
        offset: state.page * state.pageSize,
        limit: state.pageSize,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        if (fetchMoreResult.users.length < state.pageSize) {
          this.setState({ pages: state.page + 1 });
        }
        return {
          users: fetchMoreResult.users,
        };
      },
    });
  };

  onRowClick = (state, rowInfo, column, instance) => {
    return {
      onClick: (e) => {
        this.props.history.push(`/admin/accounts/${rowInfo.row.id}`);
      },
    };
  };

  render() {
    return (
      <div className="Accounts">
        <MainHeader title="Accounts">
          <Link to="/admin/accounts/new" className="button">
            Create new user
          </Link>
        </MainHeader>
        <MainContent>
          <ReactTable
            className="PD-table -highlight"
            data={this.props.data.users || []}
            defaultPageSize={100}
            loading={this.props.data.loading}
            columns={this.columns}
            loadingText="Loading users..."
            noDataText={this.props.data.loading ? "" : "No users found."}
            defaultSorted={[
              {
                id: "name",
                desc: true,
              },
            ]}
            getTrProps={this.onRowClick}
            onFetchData={this.fetchData}
            ofText=""
            onPageSizeChange={() => this.setState({ pages: 100 })}
          />
        </MainContent>
      </div>
    );
  }
}

const AccountsWithData = graphql(accountsQuery, {
  fetchPolicy: "cache-and-network",
  variables: {
    limit: 100,
  },
})(Accounts);

export default AccountsWithData;
