import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import MainHeader from "../../components/Main/MainHeader";
import MainContent from "../../components/Main/MainContent";
import Snackbar from "../../components/Snackbar/Snackbar";
import Chips from "../../components/Chips/Chips";
import Loading from "../../components/Loading/Loading";
import { unique_array, updateLoggedInUser } from "../../utils/utils";
import compose from "lodash.flowright";
import "./MyAccount.scss";

const accountQuery = gql`
  query($id: Int!) {
    user(id: $id) {
      id
      test_devices {
        installation_id
      }
    }
  }
`;

const accountQueryOptions = {
  options: (ownProps) => {
    return {
      variables: { id: parseInt(ownProps.match.params.id, 10) },
      fetchPolicy: "no-cache",
    };
  },
};

const addTestDeviceMutation = gql`
  mutation addTestDevice($user_id: Int!, $installation_id: String!) {
    addTestDevice(user_id: $user_id, installation_id: $installation_id) {
      id
      test_devices {
        installation_id
      }
    }
  }
`;

const removeTestDeviceMutation = gql`
  mutation removeTestDevice($user_id: Int!, $installation_id: String!) {
    removeTestDevice(user_id: $user_id, installation_id: $installation_id) {
      id
      test_devices {
        installation_id
      }
    }
  }
`;

class MyAccount extends React.Component {
  constructor(props) {
    super(props);

    // id will be 'new' when creating user or actual id when updating user.
    let id = parseInt(props.match.params.id, 10);

    this.state = {
      id: id,
      name: "",
      email: "",
      password: "",
      role: "user",
      team: "",
      team_id: null,
      team_key: "",
      appIdentifiers: [],
      installation_ids: [],
      snackbarMessage: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.data.user) return;

    let newState = { ...nextProps.data.user };

    // Flatten 'test_devices' array.
    const test_devices = nextProps.data.user.test_devices || [];
    newState.installation_ids = unique_array(
      test_devices.map((c) => c.installation_id)
    );

    this.setState(newState);
  }

  handleSnackbarCloseRequest = () => {
    this.setState({ snackbarMessage: "" });
  };

  handleAddInstallationId = (installation_id) => {
    // const form = document.querySelector(".account-form");
    // console.log(form);
    // const isValid = form.reportValidity();
    // console.log("isValid: ", isValid);
    const installation_ids = [...this.state.installation_ids, installation_id];
    this.setState({
      installation_ids: installation_ids,
    });

    this.props
      .addTestDevice({
        variables: {
          user_id: this.state.id,
          installation_id: installation_id,
        },
      })
      .then((res) => {
        updateLoggedInUser({ test_devices: installation_ids });
        console.log("then", res);
      })
      .catch((res) => {
        console.log("catch", res);
      });
  };

  handleDeleteInstallationId = (installation_id) => {
    const installation_ids = this.state.installation_ids.filter(
      (iid) => iid !== installation_id
    );
    this.props
      .removeTestDevice({
        variables: {
          user_id: this.state.id,
          installation_id: installation_id,
        },
      })
      .then((res) => {
        updateLoggedInUser({ test_devices: installation_ids });
        console.log("then", res);
      })
      .catch((res) => {
        console.log("catch", res);
      });

    this.setState({ installation_ids });
  };

  render() {
    if (this.props.data && this.props.data.loading) {
      return <Loading />;
    }

    return (
      <div className="Account">
        <MainHeader title="My Account" />
        <MainContent>
          <div className="section">
            <div className="section-heading">
              {this.state.id === "new" ? "Create account" : "Account Details"}
            </div>
            <div className="section-content">
              <form
                name="account-form"
                className="account-form"
                onSubmit={this.onSubmit}
              >
                <div className="input-box">
                  <Chips
                    className="test-devices"
                    heading="Test devices"
                    chips={this.state.installation_ids}
                    onDeleteChip={this.handleDeleteInstallationId}
                    onAddChip={this.handleAddInstallationId}
                    placeholder="Add installation id"
                    shouldRender={this.state.id !== "new"}
                    //pattern=".{8}-.{4}-.{4}-.{4}-.{12}"
                    title="Format: aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee"
                  />
                </div>
              </form>
            </div>
          </div>
        </MainContent>
        <Snackbar
          message={this.state.snackbarMessage}
          onRequestClose={this.handleSnackbarCloseRequest}
        />
      </div>
    );
  }
}

const MyAccountWithData = compose(
  graphql(accountQuery, accountQueryOptions),
  graphql(addTestDeviceMutation, { name: "addTestDevice" }),
  graphql(removeTestDeviceMutation, { name: "removeTestDevice" })
)(MyAccount);

export default MyAccountWithData;
