import React from "react";
import "./Select.scss";

class Select extends React.Component {
  render() {
    const {
      render = true,
      className = "",
      heading = null,
      options,
      ...selectProps
    } = this.props;

    if (!render) return null;

    return (
      <div className={`Select ${className}`}>
        <div className="heading">{heading}</div>

        <select
          name={selectProps.name}
          value={selectProps.value}
          onChange={selectProps.onChange || null}
          disabled={selectProps.disabled}
        >
          {options.map(opt => {
            return (
              <option
                key={opt.value}
                value={opt.value}
                data-option={opt.data || null}
              >
                {opt.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

export default Select;
