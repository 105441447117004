import React from "react";
import moment from "moment";
import "./Clock.scss";
class Clock extends React.Component {
  constructor() {
    super();
    this.state = {
      time: moment().format("HH:mm"),
      date: moment().format("dddd DD MMMM")
    };

    this.timer = null;
  }

  componentWillMount() {
    this.timer = setInterval(() => {
      this.setState({
        time: moment().format("HH:mm"),
        date: moment().format("dddd DD MMMM")
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <div className="Clock">
        <div className="time">{this.state.time}</div>
        <div className="date">{this.state.date}</div>
      </div>
    );
  }
}

export default Clock;
