import React from "react";
import ReactTable from "react-table";
import moment from "moment";
import { uuidv4 } from "../../utils/utils";
import Select from "../../components/Select/Select";
import Snackbar from "../../components/Snackbar/Snackbar";
import PushCampaignSendButton from "../../components/Push/PushCampaignSendButton";

import "./PushCampaigns.scss";


class PushCampaignMessages extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        id: "uniqueId",
        Header: "UniqueId",
        accessor: "uniqueId",
        show: false
      },
      {
        id: "send_date",
        Header: "Date",
        accessor: "send_date",
        width: 120,
        Cell: cellInfo =>
          this.renderEditableCell(cellInfo, {
            placeholder: "YYYY-MM-DD"
          })
      },
      {
        id: "send_time",
        Header: "Time",
        accessor: "send_time",
        width: 70,
        Cell: cellInfo =>
          this.renderEditableCell(cellInfo, {
            placeholder: "HH:mm"
          })
      },
      {
        id: "title",
        Header: "Title",
        accessor: "title",
        Cell: this.renderEditableCell
      },
      {
        id: "message",
        Header: "Message",
        accessor: "message",
        Cell: cellInfo => this.renderEditableCell(cellInfo)
      },
      {
        id: "article_url",
        Header: "Article URL",
        accessor: "article_url",
        Cell: cellInfo =>
          this.renderEditableCell(cellInfo, {
            placeholder: "http://example.com/biljetter"
          })
      },
      {
        id: "audience",
        Header: "Audience",
        accessor: "audience",
        width: 180,
        Cell: this.renderSelectAudienceCell
      },
      {
        id: "action",
        Header: "",
        Cell: cellInfo => {
          return (
            <a
              className="remove-message-button"
              href={null}
              onClick={() => {
                this.handleClickRemoveCampaignMessage(cellInfo);
              }}
            >
              Remove
            </a>
          );
        },
        width: 150,
        style: { justifyContent: "center" }
      }
    ];    

    this.state = {
      campaign_messages: props.campaignMessages || [],
      snackbarMessage: "",
      copyCampaignMessagesDisabled: false,
      campaign_select: "",
      actionButtonsDisabled: false
    };
  }

  handleClickRemoveCampaignMessage = cellInfo => {
    const campaign_messages = this.state.campaign_messages.filter(
      msg => cellInfo.row.uniqueId !== msg.uniqueId
    );
    this.setState({ campaign_messages });
  };

  handleClickAddRow = () => {
    const inAnHour = moment().add(1, 'hour');
    const campaign_messages = [
      ...this.state.campaign_messages,
      {
        send_date: inAnHour.format("YYYY-MM-DD"),
        send_time: inAnHour.format("HH:mm"),
        title: "",
        message: "",
        article_url: "",
        audience: "all-my-users",
        uniqueId: uuidv4()
      }
    ];
    this.setState({
      campaign_messages
    });
  };

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
  }

  setCampaignMessages = (messages) => {
    this.setState( {campaign_messages : messages});
  };

  handleSnackbarCloseRequest = () => {
    this.setState({ snackbarMessage: "" });
  };

  setSnackbarMessage = message => {
    this.setState({ snackbarMessage: message });
  };

  disableSendButtons = disable => {
    this.setState({ actionButtonsDisabled: disable });
  };

  renderMessageTimeWarning = () => {
    const now = moment();
    const timeHasPassedMessage = this.state.campaign_messages.find((message) => {
      const messageDateTime = moment(message.send_date + ' ' + message.send_time, 'YYYY-MM-DD HH:mm');
      return now.isAfter(messageDateTime);
    });

    if (timeHasPassedMessage) {
      return (<div className="warning-past-time">There are messages scheduled for past time. Those messages will be sent immediately.</div>);
    } else {
      return (<div />);
    }

  }

  hasTooLateMessages = () => {
    const twoWeeksFromNow = moment().add(13, "day");
    const timeHasPassedMessage = this.state.campaign_messages && this.state.campaign_messages.find((message) => {
      const messageDateTime = moment(message.send_date + ' ' + message.send_time, 'YYYY-MM-DD HH:mm');
      return twoWeeksFromNow.isBefore(messageDateTime);
    });
    return !!timeHasPassedMessage;
  }

  renderEditableCell = (cellInfo, attributes = {}) => {
    return (
      <input
        className="editable-cell"
        placeholder={attributes.placeholder || ""}
        onChange={e => {
          const campaign_messages = [...this.state.campaign_messages];
          campaign_messages[cellInfo.index][cellInfo.column.id] =
            e.target.value;
          this.setState({ campaign_messages });
        }}
        value={
          this.state.campaign_messages[cellInfo.index][cellInfo.column.id] || ""
        }
      />
    );
  };

  renderSelectAudienceCell = cellInfo => {
    return (
      <React.Fragment>
        <div
          className="editable-cell"
          style={{ display: "none" }}
          contentEditable
          suppressContentEditableWarning
          dangerouslySetInnerHTML={{
            __html: this.state.campaign_messages[cellInfo.index][
              cellInfo.column.id
            ]
          }}
        />
        <Select
          name="audience-select"
          className="audience-select"
          options={[
            { label: "All my users", value: "all-my-users" },
            { label: "All users on wifi today", value: "all-users-on-wifi" },
            {
              label: "All users not on wifi today",
              value: "all-users-not-on-wifi"
            },
            { label: "My test users", value: "my-test-users" }
          ]}
          value={
            this.state.campaign_messages[cellInfo.index][cellInfo.column.id]
          }
          onChange={e => {
            const campaign_messages = [...this.state.campaign_messages];
            campaign_messages[cellInfo.index][cellInfo.column.id] =
              e.target.value;
            this.setState({ campaign_messages });
          }}
        />
      </React.Fragment>
    );
  };

  render() {
    
    return (
      <div className="PushCampaignMessages">                              
          {!this.state.campaign_messages ? null : (
            <ReactTable
              className="PD-table"
              data={this.state.campaign_messages}
              loading={this.props.loading}
              columns={this.columns}
              contentEditable
              loadingText="Loading campaigns..."
              noDataText={""}
              defaultSorted={[
                {
                  id: "send_date",
                  desc: false
                }
              ]}
              showPagination={false}
              minRows={3}
              SubComponent={row => {
                return (
                  <div className="campaign-preview">
                    <div className="campaign-preview-title">
                      {row.original.title}
                    </div>
                    <div className="campaign-preview-message">
                      {row.original.message}
                    </div>
                    <div className="campaign-preview-article_url">
                      {row.original.article_url}
                    </div>
                  </div>
                );
              }}
            />
          )}
          {this.state.campaign_messages && this.renderMessageTimeWarning()}
          {this.hasTooLateMessages() ? <div className="warning-past-time">Publishing is disabled. There are messages scheduled for later than two weeks in the future.</div> : ''}
          <div className="action-buttons">
            <input
              type="submit"
              className="add-row-button"
              value="Add row"
              disabled={this.state.actionButtonsDisabled}
              onClick={this.handleClickAddRow}
            />
            <div className="publish-buttons">
              <PushCampaignSendButton
                className="send-all-to-testdevice-button"
                buttonText="Send all to test device"
                campaignMessages={this.state.campaign_messages}
                disabled={this.state.actionButtonsDisabled}
                disableSendButtons={this.disableSendButtons}
                snackbarMessage={this.setSnackbarMessage}
                sendTestMessages={true}
                campaignId={this.props.campaignId}
              />
              <PushCampaignSendButton
                className="publish-all-button"
                buttonText="Publish all"
                campaignMessages={this.state.campaign_messages}
                disabled={this.state.actionButtonsDisabled || this.hasTooLateMessages()}
                disableSendButtons={this.disableSendButtons}
                snackbarMessage={this.setSnackbarMessage}
                campaignId={this.props.campaignId}
              />
            </div>                                       
          </div>        
        <Snackbar
          message={this.state.snackbarMessage}
          onRequestClose={this.handleSnackbarCloseRequest}
        />
      </div>      
    );
  }
}

export default PushCampaignMessages;
