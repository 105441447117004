import React from "react";
import Loading from "../../components/Loading/Loading";
import "./Modal.scss";

class Modal extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false
    };
  }

  componentWillReceiveProps(nextProps) {
    clearTimeout(this.timer);
    if (nextProps.message) {
      this.timer = setTimeout(this.props.onTimeout, this.props.timeout || 3000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { message = "", showLoading = true } = this.props;

    if (!this.props.message) return null;

    return (
      <div className="Modal">
        <div className="message">
          {message}
          {showLoading ? <Loading /> : null}
        </div>
      </div>
    );
  }
}

export default Modal;
