import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";
import { ucFirst, uuidv4 } from "../../utils/utils";
import MainHeader from "../../components/Main/MainHeader";
import MainContent from "../../components/Main/MainContent";
import Select from "../../components/Select/Select";
import Loading from "../../components/Loading/Loading";
import Snackbar from "../../components/Snackbar/Snackbar";
import PushCampaignMessages from "./PushCampaignMessages";
import compose from "lodash.flowright";

import "./PushCampaigns.scss";

const eventInfoQuery = gql`
  query($id: Int!) {
    eventInfo(id: $id) {
      id
      hometeamid
      awayteamid
      timestamp
      arena
      ticketurl
      audience
      away_team
      home_team
      day
      time
    }
    defaultCampaigns {
      id
      name
    }
    previousCampaigns {
      id
      event_name
      team_id
      event_id
      timestamp
    }
  }
`;

const eventInfoQueryOptions = {
  options: (ownProps) => {
    return {
      variables: { id: parseInt(ownProps.match.params.id, 10) },
    };
  },
};

const loadCampaignMutation = gql`
  mutation loadCampaign(
    $event_id: Int!
    $default_campaign_id: Int
    $previous_campaign_id: Int
  ) {
    loadCampaign(
      event_id: $event_id
      default_campaign_id: $default_campaign_id
      previous_campaign_id: $previous_campaign_id
    ) {
      id
      messages {
        message
        title
        article_url
        audience
        send_now
        send_date
        send_time
        campaign_id
      }
      event_info {
        id
        hometeamid
        awayteamid
        home_team
        away_team
        day
        timestamp
        arena
        ticketurl
      }
      next_event_info {
        id
        hometeamid
        awayteamid
        home_team
        away_team
        day
        timestamp
        arena
        ticketurl
      }
    }
  }
`;

const loadCampaignMutationOptions = {
  name: "loadCampaign",
  options: (ownProps) => {
    return {
      variables: { event_id: parseInt(ownProps.match.params.id, 10) },
    };
  },
};

class PushCampaignSetup extends React.Component {
  constructor() {
    super();

    this.state = {
      snackbarMessage: "",
      copyCampaignMessagesDisabled: false,
      campaign_select: "",
      actionButtonsDisabled: false,
    };
  }

  handleSelectCampaignTemplateChange = (e) => {
    this.setState({
      // copyCampaignMessagesDisabled: true,
      [e.target.name]: e.target.value,
      snackbarMessage: "Copying campaign messages...",
    });

    e.preventDefault();
    // Parse selected campaign template.
    const [campaignType, campaignId] = e.target.value.split(":");
    let campaignIdVariable = "";
    if (campaignType.toLowerCase() === "campaigninfo")
      campaignIdVariable = "default_campaign_id";
    else if (campaignType.toLowerCase() === "previouscampaigninfo")
      campaignIdVariable = "previous_campaign_id";

    if (!campaignIdVariable || !campaignId) {
      this.setState({
        snackbarMessage: "Please set a campaign template.",
        copyCampaignMessagesDisabled: false,
      });
      return;
    }

    const options = {
      variables: {
        [campaignIdVariable]: parseInt(campaignId, 10),
      },
    };

    // Load campaign.
    this.props
      .loadCampaign(options)
      .then((data) => {
        const campaign_messages = [...data.data.loadCampaign.messages].map(
          (msg) => {
            // Add unique id so user can remove message.
            return { ...msg, uniqueId: uuidv4() };
          }
        );
        this.messagesComponent.setCampaignMessages(campaign_messages);
        this.setState({
          campaign: data.data.loadCampaign,
          snackbarMessage: "Campaign messages copied.",
        });
      })
      .catch((res) => {
        this.setState({
          snackbarMessage: res.message,
          copyCampaignMessagesDisabled: false,
        });
      });
  };

  handleSnackbarCloseRequest = () => {
    this.setState({ snackbarMessage: "" });
  };

  setSnackbarMessage = (message) => {
    this.setState({ snackbarMessage: message });
  };

  disableSendButtons = (disable) => {
    this.setState({ actionButtonsDisabled: disable });
  };

  render() {
    if (this.props.data.loading) return <Loading />;
    if (this.props.data.error) {
      console.log(this.props.data.error);
      return <Loading />;
    }

    const { eventInfo } = this.props.data;

    // Create array of default/previous campaigns to put in Select.
    const campaignSelectOptions = [
      { label: "Select campaign...", value: "" },
      ...this.props.data.defaultCampaigns.map((campaign) => {
        return {
          label: campaign.name,
          value: `${campaign.__typename}:${campaign.id}`,
        };
      }),
      ...this.props.data.previousCampaigns.map((campaign) => {
        return {
          label: campaign.event_name,
          value: `${campaign.__typename}:${campaign.id}`,
        };
      }),
    ];

    return (
      <div className="PushCampaignSetup">
        <MainHeader title="Setup campaign" />
        <MainContent>
          <div className="section">
            <div className="section-heading">
              {eventInfo.home_team} - {eventInfo.away_team}
            </div>
            <div className="section-subheading">
              {eventInfo.arena}, {eventInfo.day}{" "}
              {moment(eventInfo.timestamp).format("YYYY-MM-DD HH:mm")}
            </div>
            <div className="section-content">
              <div className="campaign-selector">
                <Select
                  heading="Campaign template"
                  name="campaign_select"
                  options={campaignSelectOptions}
                  onChange={this.handleSelectCampaignTemplateChange}
                  disabled={this.state.copyCampaignMessagesDisabled}
                />
              </div>

              <PushCampaignMessages
                loading={this.props.data.loading}
                onRef={(ref) => (this.messagesComponent = ref)}
              />

              <div className="eventInfos">
                {this.state.campaign && this.state.campaign.event_info ? (
                  <div className="next-game-info section">
                    <div className="section-heading">This event</div>
                    <div className="section-content">
                      <div className="eventInfo infobox">
                        <div className="eventInfoItem">
                          {this.state.campaign.event_info.home_team} -{" "}
                          {this.state.campaign.event_info.away_team}
                        </div>
                        <div className="eventInfoItem">
                          {ucFirst(this.state.campaign.event_info.day)}{" "}
                          {this.state.campaign.event_info.timestamp}
                        </div>
                        <div className="eventInfoItem">
                          {this.state.campaign.event_info.arena}
                        </div>
                        <div className="eventInfoItem">
                          Ticket URL:{" "}
                          <a
                            href={this.state.campaign.event_info.ticketurl}
                            target="_blank"
                          >
                            {this.state.campaign.event_info.ticketurl}
                          </a>
                        </div>
                        {/* <pre>{JSON.stringify(eventInfo, null, "    ")}</pre> */}
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.campaign && this.state.campaign.next_event_info ? (
                  <div className="next-game-info section">
                    <div className="section-heading">Next event</div>
                    <div className="section-content">
                      <div className="eventInfo infobox">
                        <div className="eventInfoItem">
                          {this.state.campaign.next_event_info.home_team} -{" "}
                          {this.state.campaign.next_event_info.away_team}
                        </div>
                        <div className="eventInfoItem">
                          {ucFirst(this.state.campaign.next_event_info.day)}{" "}
                          {this.state.campaign.next_event_info.timestamp}
                        </div>
                        <div className="eventInfoItem">
                          {this.state.campaign.next_event_info.arena}
                        </div>
                        <div className="eventInfoItem">
                          Ticket URL:{" "}
                          <a
                            href={this.state.campaign.next_event_info.ticketurl}
                            target="_blank"
                          >
                            {this.state.campaign.next_event_info.ticketurl}
                          </a>
                        </div>
                        {/* <pre>{JSON.stringify(eventInfo, null, "    ")}</pre> */}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </MainContent>
        <Snackbar
          message={this.state.snackbarMessage}
          onRequestClose={this.handleSnackbarCloseRequest}
        />
      </div>
    );
  }
}

export default compose(
  graphql(eventInfoQuery, eventInfoQueryOptions),
  graphql(loadCampaignMutation, loadCampaignMutationOptions)
)(PushCampaignSetup);
