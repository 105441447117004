import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import "./assets/styles/reset.css";
import "./assets/styles/index.css";
import App from "./components/App/App";
import registerServiceWorker from "./registerServiceWorker";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists.
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const token = loggedInUser && loggedInUser.token ? loggedInUser.token : null;

  // return the headers to the context so httpLink can read them.
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    console.log("networkError", networkError);
  }
  if (graphQLErrors) {
    const isNotAuthenticated = graphQLErrors.find(err => {
      return err.message && err.message === "Not authenticated";
    });

    if (isNotAuthenticated) {
      localStorage.removeItem("loggedInUser");
      // this.props.history.push("/login"); can't do this, but how else redirect??
    }
  }
  graphQLErrors = null;
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache()
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

registerServiceWorker();
